<template>
  <div class="transfer">
    <div class="heading">
      <h2>Transaction Pin</h2>
      <p>Set your transaction pin</p>
    </div>

    <form class="form" @submit.prevent="submitForm" novalidate="true">
      <div class="form__group">
        <label for="pinCurrent">
          <input
            id="pinCurrent"
            :type="passwordFieldTypeThree"
            name="pinCurrent"
            class="form__input"
            placeholder="Current Pin"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model="$v.form.pinCurrent.$model"
            :class="{ 'is-invalid': submitted && $v.form.pinCurrent.$error }"
        /></label>
        <span @click="switchVisibilityThree" class="form__password">{{
          passwordFieldTypeThree === "password" ? "Show" : "Hide"
        }}</span>
        <span v-if="submitted && $v.form.pinCurrent.$error" class="error">
          <small class="error" v-if="!$v.form.pinCurrent.required"
            >Current Pin is required</small
          >
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pinCurrent.numeric"
            >Needs to be numeric only.</small
          >
          <!-- <br /> -->
          <small
            class="error"
            v-if="!$v.form.pinCurrent.maxLength || !$v.form.pinCurrent.minLength"
            >Pin must be 4 numbers.</small
          >
        </span>
      </div>

      <div class="form__group">
        <label for="pin">
          <input
            id="pin"
            :type="passwordFieldType"
            name="pin"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            placeholder="New Pin"
            v-model="$v.form.pin.$model"
            :class="{ 'is-invalid': submitted && $v.form.pin.$error }"
        /></label>
        <span @click="switchVisibility" class="form__password">{{
          passwordFieldType === "password" ? "Show" : "Hide"
        }}</span>
        <span v-if="submitted && $v.form.pin.$error" class="error">
          <small class="error" v-if="!$v.form.pin.required">New Pin is required</small>
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pin.numeric"
            >Needs to be numeric only.</small
          >
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pin.maxLength || !$v.form.pin.minLength"
            >Pin must be 4 numbers.</small
          >
        </span>
      </div>

      <div class="form__group">
        <label for="pinConfirm">
          <input
            id="pinConfirm"
            :type="passwordFieldTypeTwo"
            name="pinConfirm"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            placeholder="Confirm Pin"
            v-model="$v.form.pinConfirm.$model"
            :class="{
              'is-invalid': submitted && $v.form.pinConfirm.$error,
            }"
        /></label>
        <span @click="switchVisibilityTwo" class="form__password">{{
          passwordFieldTypeTwo === "password" ? "Show" : "Hide"
        }}</span>
        <span v-if="submitted && $v.form.pinConfirm.$error" class="error">
          <small class="error" v-if="!$v.form.pinConfirm.required"
            >Confirm pin is required</small
          >
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pinConfirm.sameAsPin"
            >Pins do not match.</small
          >
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pinConfirm.numeric"
            >Needs to be numeric only.</small
          >
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pin.maxLength || !$v.form.pin.minLength"
            >Pin must be 4 numbers.</small
          >
        </span>
      </div>

      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Continue</span>
      </button>
    </form>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import {
  required,
  sameAs,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
export default {
  computed: mapState(["user"]),
  data() {
    return {
      passwordFieldType: "password",
      passwordFieldTypeTwo: "password",
      passwordFieldTypeThree: "password",
      loading: false,
      submitted: false,
      form: {
        pin: "",
        pinConfirm: "",
        pinCurrent: "",
      },
    };
  },
  validations: {
    form: {
      pin: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      pinConfirm: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
        sameAsPin: sameAs("pin"),
      },
      pinCurrent: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
    },
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    switchVisibilityTwo() {
      this.passwordFieldTypeTwo =
        this.passwordFieldTypeTwo === "password" ? "text" : "password";
    },
    switchVisibilityThree() {
      this.passwordFieldTypeThree =
        this.passwordFieldTypeThree === "password" ? "text" : "password";
    },
    clearData() {
      this.form.pin = "";
      this.form.pinConfirm = "";
      this.form.pinCurrent = "";
      this.submitted = false;
    },
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      let payload = {
        userId: this.user.profileInfo.SMID,
        oldtransactionpin: this.form.pinCurrent,
        newtransactionpin: this.form.pin,
      };

      this.loading = true;

      api
        .changeTransactionPin(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "success",
              duration: 10000,
            });
            this.clearData();
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
